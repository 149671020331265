import {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import Logout from "../components/Logout";
import CameraImg from "../assets/camera.svg";
import MobileView from "../components/MobileView";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { handleCheck } from "../helpers";
import { RunnerContext } from "../Main";

export const ResultContext = createContext(null);
export default function Home() {
  const [query, setQuery] = useState("");
  const [res, setRes] = useState([]);
  const { runnerData, setRunnerData } = useContext(RunnerContext);
  const checkAccessToken = useCallback(async () => {
    await handleCheck(runnerData, setRunnerData);
  }, [runnerData]);
  const isMobile = !navigator.userAgent.includes("Windows");
  const [isCameraOpen, setCameraOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  const filteredRes = res.filter(onlyUnique);
  const handleQuerySubmit = e => {
    e.preventDefault();
    if (query !== "") {
      setRes(old => [...old, query.trim()]);
      setQuery("");
    }
  };
  const handleQueryChange = e => {
    setQuery(e.target.value.trim().toUpperCase());
  };
  useEffect(() => {
    if (runnerData !== null) {
      checkAccessToken();
    }
  }, [runnerData]);
  return (
    <>
      <ResultContext.Provider value={{ res, setRes }}>
        <div className="form-wrapper">
          <form onSubmit={handleQuerySubmit} className="header-form">
            <input
              type="text"
              className="scan-container"
              value={query}
              onChange={handleQueryChange}
            />
          </form>
          <div onClick={() => setCameraOpen(old => !old)}>
            <img
              src={CameraImg}
              style={{ width: "30px", height: "30px" }}
              alt=""
            />
          </div>
          <Logout />
        </div>
        <div>
          <div className="scanned-status">
            <div className="scanned-title">Scanned Shipments</div>
            <div className="scanned-number">{filteredRes.length}</div>
          </div>
          <div className="scanned-container">
            {isCameraOpen ? <MobileView /> : null}
            <ul className="shipments-list">
              {filteredRes.map(r => (
                <li className="scanned" key={r}>
                  <div>{r}</div>
                  <div
                    className="close"
                    onClick={() => {
                      setRes(old => old.filter(result => result !== r));
                    }}
                  >
                    x
                  </div>
                </li>
              ))}
            </ul>
            {filteredRes.length > 0 && (
              <div
                className="confirmation-btn"
                onClick={() => {
                  setIsConfirmOpen(true);
                }}
              >
                confirm
              </div>
            )}
          </div>
          {isConfirmOpen && (
            <ConfirmationDialog
              shipments={filteredRes}
              setOpen={setIsConfirmOpen}
            />
          )}
        </div>
      </ResultContext.Provider>
    </>
  );
}
