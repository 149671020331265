import { useEffect, createContext, useState } from "react";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { getCompanyData } from "./helpers";
import { Home, Login } from "./pages";
export const RunnerContext = createContext();
export default function Main() {
  const navigate = useNavigate();
  const [runnerData, setRunnerData] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("runnerData") && !runnerData) {
      setRunnerData(JSON.parse(localStorage.getItem("runnerData")));
    }
  }, [runnerData]);
  useEffect(()=>{
    console.log("runnerData" , runnerData)
  },[runnerData])
  useEffect(() => {
    if (runnerData) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [runnerData, navigate]);

  return (
    <RunnerContext.Provider value={{ runnerData, setRunnerData }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
      </Routes>
    </RunnerContext.Provider>
  );
}
