import { useState, useContext } from "react";
import { handleRunnerData } from "../helpers";
import { RunnerContext } from "../Main";
export default function Login() {
  const { setRunnerData } = useContext(RunnerContext);
  const [loading , setLoading ] = useState(false)
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    companyID: "",
  });
  const handleFormDataChange = (e) => {
    setFormState((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    await handleRunnerData().login(formState, setRunnerData);
    setLoading(false)
  };
  if(loading) return <>loading</>
  return (
    <>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-control">
          <label htmlFor="username" > username</label>
          <input
            required
            className="scan-container"
            name="username"
            value={formState.username}
            onChange={handleFormDataChange}
          />
        </div>
        <div className="form-control">
          <label htmlFor="password"
          > password</label>

          <input
          required
            name="password"
            className="scan-container"
            type="password"
            value={formState.password}
            onChange={handleFormDataChange}
          />
        </div>
        <div className="form-control">
          {" "}
          <label htmlFor="companyID"> CompanyID</label>
          <input
          required
            name="companyID"
            className="scan-container"
            value={formState.companyID}
            onChange={handleFormDataChange}
          />
        </div>

        <button type="submit">login</button>
      </form>
    </>
  );
}
